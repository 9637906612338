import React from "react"
import Headings from "./headings"

const CommentsBubbles = () => {
  return (
    <>
        <Headings
          title="Customer Comments"
          description="JWR are proud when we get positive comments from our clients, who are happy with our services provided. 
          Please see some of the comments below from a few of our customers"
        />
        <div
          uk-grid="true"
          className="uk-child-width-1-1 tm-comment-list uk-margin-small-top"
        >
          <div>
            <div uk-grid="true" className="uk-grid-collapse">
              <div className="uk-width-1-1@s uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p>
                    Jordan is a very experienced professional and he is able to promote personal and professional 
                    improvements among colleagues and partners.
                  </p>
                  <p>Careful expert with a great skill to analyse and solve problems. Hardworking, intelligent and 
                    helpful, he has come a long way and is sure to achieve greater heights. I would recommend him to anyone.</p>
                </div>
              </div>
              <div className="uk-width-1-6@m uk-flex-first@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5"></span>
                    <h6 className="uk-margin-top">
                      Edward Macintyre
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div uk-grid="true" className="uk-grid-collapse">
              <div className="uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p>
                    I can highly recommend Darryl, who has done a variety of work for me on a number of occasions. 
                    He is excellent and professional in everything he does, and takes great care and pride in his craft.
                  </p>
                  <p>
                    He’s reliable, friendly, honest, trustworthy and with very reasonable rates. An all-round nice guy. 
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left@m uk-flex-right">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5"></span>
                    <h6 className="uk-margin-top">Christine</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <div uk-grid="true" className="uk-grid-collapse">
              <div className="uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p>
                    What Chulie does is amazing. She is very transparent in what
                    she does and that is refreshing because as donors, we need
                    to know where it goes.
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m uk-flex-first@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5"></span>
                    <h6 className="uk-margin-top">Maria Madelena Gouveia</h6>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div>
            <div
              uk-grid="true"
              className="uk-grid-collapse"
              style={{ backgroungColor: "red" }}
            >
              <div className="uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p>
                    What would these poor little dogs do without you and
                    everyone else who helps you. The care, time and love you
                    give them is beyond amazing.
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left@m uk-flex-right">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5"></span>
                    <h6 className="uk-margin-top">Lynne Jackson</h6>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

        </div>
    </>
  )
}

export default CommentsBubbles
