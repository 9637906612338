import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
//import Headings from "../components/headings"

const Champion = () => {
  return (
    <div
      className="uk-grid-collapse uk-child-width-1-2@m uk-grid-match uk-flex-middle uk-light uk-margin-medium-top"
      data-uk-grid="true"
      style={{ backgroundColor: "#687169" }}
    >
      <div className="uk-flex-last@m">
        <div>
          <StaticImage
            src="../images/client-properties-london.jpg"
            alt="Chulie Perera"
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="blurred"
            title="Chulie Perera"
            aspectRatio={1.0}
          />
        </div>
      </div>
      <div>
        <div className="uk-padding">
          <h4
            className="green-grad uk-margin-right"
            style={{ fontWeight: "700", fontSize: "26px" }}
          >
            <span>James Walter Raymond Ltd</span>
          </h4>   
          <p style={{ color: "#FFFFFF" }}>
            Jordan Pullinger is an extremely committed and enthusiastic individual 
            that I had the pleasure to work with and mentor during his time as a junior 
            avionics supervisor working on AW101 Merlin Mk2 aircraft.
          </p>
          <p style={{ color: "#FFFFFF" }}>
            During time we worked together Jordan regularly assisted with complex avionics 
            fault diagnosis where his in-depth system knowledge allowed him to make great 
            contribution to the team. His thirst for knowledge and ability to learn new 
            information and skills quickly allows him to be an effective maintenance supervisor 
            with the potential for advancement within his chosen path. Jordan always maintains 
            the highest levels of personal standards and professionalism, which in turn directly 
            affects the team he has been set to lead through any maintenance activity. I have no 
            doubt Jordan will achieve great things in the future.
          </p>
          <Link
            to="/article/a-day-with-chulie"
            className="hover-underline-animation-white"
            style={{ color: "#FFFFFF", textDecoration: "none" }}
          >
            Aiden Campbell
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Champion
