import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const FeaturedArticle = ({ featuredArticle }) => {
  //console.log("Featured Post: ", featuredArticle)

  return (
    <section className="uk-section uk-section-small uk-padding-remove">
      <div className="uk-container">
        <div className="uk-height-large uk-cover-container uk-border-rounded uk-margin-medium-bottom">
          {featuredArticle?.image && (
            <GatsbyImage
              image={getImage(featuredArticle.image.localFile)}
              alt={featuredArticle.title}
              title={featuredArticle.title}
              //data-uk-cover="true"
              style={{ height: "100%", width: "100%" }}
            />
          )}
          <div className="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-dark uk-text-center">
            <div data-uk-scrollspy="cls: uk-animation-slide-bottom-small ">
              <span
                style={{
                  color: "#FFFFFF",
                  textShadow: "2px 2px 4px #000000",
                  styleLetterSpacing: "0.2em",
                  fontSize: "0.725rem",
                }}
              >
                {featuredArticle.smallTitle}
              </span>
              <div
                className="uk-margin-small-top uk-margin-small-bottom "
                style={{
                  color: "#FFFFFF",
                  textShadow: "2px 2px 4px #000000",
                  styleLetterSpacing: "0.2em",
                  fontSize: "clamp(1.4rem, 6.5vw, 1.6rem)",
                }}
              >
                {featuredArticle.title}
              </div>
              <div className="uk-flex uk-flex-center uk-flex-middle">
                 <p
                  style={{
                    color: "#FFFFFF",
                    fontSize: "1rem",
                    width: "80%",
                    textShadow: "2px 2px 4px #000000",
                  }}
                >
                {featuredArticle.description}
              </p>
              </div>
             
              {featuredArticle.link.isInternal === true ? (
                <Link
                  to={featuredArticle.link.url}
                  alt={`button that links to our ${featuredArticle.title} article`}
                  title={`Click here to read our ${featuredArticle.title} article`}
                  className="uk-button uk-button-default uk-button-small uk-text-small buttonHover"
                  data-uk-icon="arrow-right"
                  style={{ borderColor: "#FFFFFF", color: "#FFFFFF", borderRadius: "8px" , paddingTop: "5px", paddingBottom: "5px"}}
                >
                  <span className="uk-margin-small-right	">GO TO ARTICLE</span>
                </Link>
              ) : (
                <a
                  href={featuredArticle.link.url}
                  alt={featuredArticle.link.name}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="uk-button uk-button-default uk-button-small uk-border-pill buttonHover"
                >
                  GO TO ARTICLE
                </a>
              )}

              <div className="uk-width-auto uk-padding-small">
               {/*  {featuredArticle?.banner && (
                  <GatsbyImage
                    image={getImage(featuredArticle.banner.localFile)}
                    alt={featuredArticle.banner.alternativeText}
                    title={featuredArticle.banner.alternativeText}
                    style={{ maxWidth: "350px" }}
                  />
                )} */}
                <span
                style={{
                  color: "#FFFFFF",
                  textShadow: "2px 2px 4px #000000",
                  styleLetterSpacing: "0.2em",
                  fontSize: "0.725rem",
                }}
              >
                JAMES WALTER RAYMOND LTD
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedArticle
