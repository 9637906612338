import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
//import Headings from "./headings"
import Markdown from "react-markdown"

const PageAdvert = ({ advert }) => {
  //console.log("PageAdvert - advert: ", advert)
  return (
    <>
      {advert.style === "imageRight" ? (
        
           <div className="uk-container" style={{ marginBottom: "20px", marginTop: "20px" }}>
              <div className={`uk-grid uk-child-width-1-2@l uk-flex-middle`}>
                <div>
                  <div className="uk-margin-small-bottom">
                    <h3 className="uk-margin-remove" style={{marginBottom: "20px"}}>{advert?.title}</h3>
                  </div>
                  <div className=" uk-margin-small-bottom">
                    <h5 className="uk-margin-remove" style={{ color: "#687169", fontWeight: "500" }} >{advert?.heading}</h5>
                  </div>
                  <div className="uk-margin-small-top">
                    <Markdown children={advert?.content?.data?.content}/>
                  </div>
                  {advert.enabled === true ? (
                    <>
                     <Link
                        to={advert?.link}
                        alt={`button that links to our ${advert?.title}`}
                        title={`Click here to learn more about our ${advert?.title}`}
                        className="uk-button uk-button-default uk-button-small uk-text-small uk-visible@l"
                        data-uk-icon="arrow-right"
                        style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                      >
                      <span className="uk-margin-small-right">
                        {advert?.buttonName}
                      </span>                      
                    </Link>

                    <Link
                        to={advert?.link}
                        alt={`button that links to our ${advert?.title}`}
                        title={`Click here to learn more about our ${advert?.title}`}
                        className="uk-button uk-button-default uk-button-small uk-text-small uk-hidden@l"
                        data-uk-icon="arrow-right"
                        style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px" }}
                      >
                      <span className="uk-margin-small-right">
                        {advert?.buttonName}
                      </span>                      
                    </Link>
                    </>                    
                  ) : null }
                </div>
                  <div data-uk-scrollspy="cls: uk-animation-fade">
                    {advert?.image && (
                      <GatsbyImage
                        image={getImage(advert?.image?.localFile)}
                        alt={advert?.title}
                        title={advert?.title}
                        style={{ width: "100%" }}
                      />
                    )}
                </div>
              </div>
            </div>
        ) : (          
          <div className="uk-container" style={{ marginBottom: "20px", marginTop: "20px" }}>
              <div className={`uk-grid uk-child-width-1-2@l uk-flex-middle`}>
                <div
                  data-uk-scrollspy="cls: uk-animation-fade"
                  className="uk-flex-first@l"
                >
                  {advert?.image && (
                    <GatsbyImage
                      image={getImage(advert?.image?.localFile)}
                      alt={advert?.title}
                      title={advert?.title}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
                <div className="uk-flex-first">
                  {/* <Headings title={advert.title} description={advert.heading} style={{fontWeight: "500" }} /> */}
                  <div className=" uk-margin-small-bottom">
                  <h3 className="uk-margin-remove" style={{marginBottom: "20px"}}>{advert?.title}</h3>
                  </div>
                  <div className=" uk-margin-small-bottom">
                    <h5 className="uk-margin-remove" style={{ color: "#687169", fontWeight: "500" }} >{advert?.heading}</h5>
                  </div>
                  <div className="uk-margin-small-top">
                    <Markdown children={advert?.content?.data?.content}/>
                  </div>
                  {advert.enabled === true ? (
                   <>
                   <Link
                      to={advert?.link}
                      alt={`button that links to our ${advert?.title}`}
                      title={`Click here to learn more about our ${advert?.title}`}
                      className="uk-button uk-button-default uk-button-small uk-text-small uk-visible@l"
                      data-uk-icon="arrow-right"
                      style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px" }}
                    >
                    <span className="uk-margin-small-right">
                      {advert?.buttonName}
                    </span>                      
                  </Link>

                  <Link
                      to={advert?.link}
                      alt={`button that links to our ${advert?.title}`}
                      title={`Click here to learn more about our ${advert?.title}`}
                      className="uk-button uk-button-default uk-button-small uk-text-small uk-hidden@l"
                      data-uk-icon="arrow-right"
                      style={{ borderColor: "#687169", color: "#687169", borderRadius: "8px", paddingTop: "5px", paddingBottom: "5px", marginBottom: "20px" }}
                    >
                    <span className="uk-margin-small-right">
                      {advert?.buttonName}
                    </span>                      
                  </Link>
                  </>      
                  ) : null }
                </div>
              </div>
            </div>
        )}
     </>
  )
}

export default PageAdvert
