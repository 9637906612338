import React from "react"
import Markdown from "react-markdown"

const TestimonialCard = ({ testimonialCard }) => {
  //console.log("testimonial Card: ", testimonialCard)
  return (
    <li>
      <div className="uk-margin-small-top uk-padding-small">
        <p style={{ color: "#FFFFFF", fontSize: "20px", textShadow: "2px 2px 4px #000000", styleLetterSpacing: "0.2em" }}>{testimonialCard.name}</p>
        <p style={{ color: "#FFFFFF", fontSize: "20px", textShadow: "2px 2px 4px #000000", styleLetterSpacing: "0.2em" }}>{testimonialCard.title}</p>
        <div 
          className="uk-margin-small-top"
          style={{ color: "#FFFFFF", fontSize: "20px", textShadow: "2px 2px 4px #000000", styleLetterSpacing: "0.2em" }}
        >
          <Markdown children={testimonialCard.content.data.content} />
        </div>
      </div>
    </li>
  )
}

export default TestimonialCard
