import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
//import TestimonialSlider from "../components/testimonial-slider"
import FeaturedArticle from "../components/featured-article"
import CommentsBubbles from "../components/comments-bubbles"
import Champion from "../components/champion"
import TestimonialSliderBackgroundImage from "../components/testimonial-slider-background-image"
import BackgroundImage from "../images/landscape-hero.jpg"
import CtaSlider from "../components/cta-slider"
//import ShareButton from "../components/share-buttons"
import PageAdvert from "../components/page-advert"
//import Markdown from "react-markdown"
//import { Iframe } from "../components/iframe"
//import StreetFeeding from "../videos/chulie-street-feeding.mp4"
//import UIkit from "uikit"

const TestimonialsPage = pageContext => {
  //console.log("TestimonialsPage - pageContext: ", pageContext)
  const { strapiTestimonial } = useStaticQuery(graphql`
    query {
      strapiTestimonial {
        hero {
          ...StrapiHero
        }
        seo {
          ...StrapiSeo
          metaSocial {
            socialNetwork
            title
            description
          }
        }
        featuredArticle {
          ...StrapiFeaturedArticle
        }
        callToAction {
          ...StrapiCallToAction
        }
        headings {
          title
          description
        }
        pageAd {
          ...PageAdverts
        }
        content {
          data {
            content
          }
        }
        testimonialSection {
          ...TestimonialCards
        }
      }
    }
  `)

  //console.log("strapiTestimonial: ", strapiTestimonial)

  const {
    hero,
    seo,
    headings,
    featuredArticle,
    callToAction,
    testimonialSection,
    content,
    pageAd,
  } = strapiTestimonial
  const { testimonial_cards } = testimonialSection
  const { call_to_action_sections } = callToAction

  //console.log("Hero: ", hero)
  //console.log("seo: ", seo)
  //console.log("headings: ", headings)
  //console.log("featuredArticle: ", featuredArticle)
  //console.log("strapiTestimonial - video: ", video)

  //const imageUrl = `${seo.metaImage.localFile.publicURL}`
  //const imageUrl = `${seo.metaImage.url}`

  const reviewsAsMissionMilestones = pageAd.page_adverts[0]
  const directorsAtTheHelm = pageAd.page_adverts[1]
  const futureReviewsFutureSuccess = pageAd.page_adverts[2]

  const imageUrl = `${strapiTestimonial.seo.metaImage.localFile.url}`

  /* console.log(
    "imageUrl: ",
    `${process.env.SITE_URL}${seo.metaImage.localFile.publicURL}`
  ) */

  //const quote = "Please share our testimonials"
  //const hashtag = "#SaveTheStreetPooches"

  //UIkit.responsive(UIkit.util.$("iframe"))

  return (
    <>
      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={imageUrl}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />
      <Hero hero={hero} />

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container">
          <Headings title={headings.title} description={headings.description} />
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove">
        <div style={{ marginTop: "38px", marginBottom: "38px"}}>
          <PageAdvert advert={reviewsAsMissionMilestones} background="uk-background-default" />
        </div>        
      </section>

      
      <section className="uk-section uk-section-muted uk-padding-remove">
        <div style={{ marginTop: "38px", marginBottom: "38px"}}>
          <PageAdvert advert={directorsAtTheHelm} background="uk-background-muted" />
        </div>        
      </section>

      <section className="uk-section uk-section-default uk-padding-remove">
        <div style={{ marginTop: "38px", marginBottom: "38px"}}>
          <PageAdvert advert={futureReviewsFutureSuccess} background="uk-background-default" />
        </div>        
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
          <Headings 
            title="Featured Review"
            description={content.data.content}
          />
          <Champion />
          {/* <div className="uk-margin-medium-top">
            <ShareButton
              //description={seo.metaDescription}
              url={pageContext.location.href}
              quote={quote}
              hashtag={hashtag}
              seo={seo}
            />
          </div> */}
        </div>
      </section>

      <TestimonialSliderBackgroundImage
        BackgroundImage={BackgroundImage}
        testimonialCards={testimonial_cards}
      />

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
          <CommentsBubbles />
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-small-bottom">
          <Headings
            title="Featured Testimonial"
            description="Please take the time to read D&D Project Solutions testimonial they have written about JWR the services we provide the work we do, and the partnership that has evolved over the yearts"
          />
          <div style={{ marginTop: "20px"}}>
            <FeaturedArticle featuredArticle={featuredArticle} />
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
            <Headings
              title={callToAction.title}
              description={callToAction.description}
            />
          <div className="uk-container uk-margin">
            <CtaSlider callToActionSections={call_to_action_sections} />
          </div>
        </div>
      </section>
    </>
  )
}

export default TestimonialsPage
